import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import PetsApiFactory from '../../pets-api/PetsApiFactory';
import { Pet } from '../../pets-api/generated-src';

export default function FormPage() {
  const history = useHistory();
  const {id, mode} = useParams<{id: string, mode: string}>();
  const petId = parseInt(id);
  const initialPetData: Pet = {
    id: petId,
    name: 'Boomer',
    tag: 'dog'
  };
  const [petData, setPetData] = useState(initialPetData);
  const PetsApi = PetsApiFactory();

  if (mode === 'edit') {
    useEffect(() => {
      (async () => {
        setPetData((await PetsApi.showPetById(petId)).data);
      })();
    }, []);
  }

  const onChange = (attribute, value) => {
    const newState = { ...petData };
    newState[attribute] = value;
    setPetData(newState);
  };

  async function save() {
    await PetsApi.createPets(petData);
    history.push('/');
  }

  return (
    <>  
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 6, s: 6, xxs: 8 }, offset: { s: 3, xxs: 1 } }
          ]}
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" href="#/">Cancel</Button>
                <Button variant="primary" onClick={save}>Submit</Button>
              </SpaceBetween>
            }
            header={
              <Header variant="h1" description="Pet information">
                Pet
              </Header>
            }
          >
            <Container >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Id" stretch>
                  <Input
                    value={petData.id.toString()}
                    ariaRequired={true}
                    placeholder="Numeric pet id"
                    disabled={mode === 'edit'}
                    onChange={({ detail: { value } }) => onChange('id', value)}
                  />
                </FormField>
                <FormField label="Name">
                  <Input
                    value={petData.name}
                    ariaRequired={true}
                    placeholder="Pet's name"
                    onChange={({ detail: { value } }) => onChange('name', value)}
                  />
                </FormField>
                <FormField label="Tag">
                  <Input
                    value={petData.tag || ''}
                    ariaRequired={true}
                    placeholder="Pet's tag (e.g. dog, cat)"
                    onChange={({ detail: { value } }) => onChange('tag', value)}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
        </Grid>
      </Box>
    </>
  );
}
