import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import PetsApiFactory from '../../pets-api/PetsApiFactory';
import { Pet } from '../../pets-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

export default function PetsTable() {
  const [allItems, setAllItems] = useState<Pet[]>();
  const [refreshTableFlag, setRefreshTableFlag] = useState(false);
  const [maxId, setMaxId] = useState(0);
  const history = useHistory();
  const createButton = (
    <Button variant="primary" iconName="add-plus" href={`#/form/create/${maxId}`}>
      Create
    </Button>
  );
  const PetsApi = PetsApiFactory();

  useEffect(() => {
    (async () => {
      setAllItems( (await PetsApi.listPets()).data );
    })();
  }, [refreshTableFlag]);

  useEffect(() => {
    setMaxId( 1 + (items.length > 0 ? Math.max(...items.map(i=>i.id)) : 0 ) );
  }, [allItems]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No records"
            subtitle="No records to display."
            action={createButton}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        )
      },
      pagination: {
        pageSize: 10
      },
      sorting: {},
      selection: {}
    }
  );

  async function deletePet(petId: number) {
    await PetsApi.deletePetById(petId);
    setRefreshTableFlag(!refreshTableFlag);
  }

  function editPet(petId: number) {
    history.push(`/form/edit/${petId}`);
  }

  const { selectedItems } = collectionProps;
  return (
    <Table
      {...collectionProps}
      loading={!allItems}
      loadingText="Loading instances"
      selectionType="single"
      header={
        <Header
          counter={
            allItems &&
            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
          }
          actions={
            <SpaceBetween direction="horizontal" size="m">
              <Button
                iconName="status-negative"
                disabled={selectedItems!.length == 0}
                onClick={() => deletePet(selectedItems![0].id)}
              >
                Delete
              </Button>

              <Button
                iconName="edit"
                disabled={selectedItems!.length == 0}
                onClick={() => editPet(selectedItems![0].id)}
              >
                Edit
              </Button>

              {createButton}
            </SpaceBetween>
          }
        >
          Records
        </Header>
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount!)}
          filteringAriaLabel="Filter records"
        />
      }
    />
  );
}
